:root,
[data-amplify-theme] {
  --amplify-colors-background-primary: #060b19;
  --amplify-colors-background-secondary: #060b19;
  --amplify-colors-background-tertiary: #060b19;
  --amplify-colors-border-primary: #f2f2f233;
  --amplify-colors-border-secondary: green;
  --amplify-colors-border-tertiary: yellow;
  --amplify-colors-brand-primary-10: #7373FF;
  --amplify-colors-brand-primary-20: #7373FF;
  --amplify-colors-brand-primary-40: #7373FF;
  --amplify-colors-brand-primary-50: #5D55F2;
  --amplify-colors-brand-primary-60: #7373FF;
  --amplify-colors-brand-primary-80: #7373FF;
  --amplify-colors-brand-primary-90: #7373FF;
  --amplify-colors-brand-primary-100: #7373FF;
  --amplify-colors-font-interactive: #f2f2f2cc;

  --amplify-components-button-primary-background-color: var(
    --amplify-colors-brand-primary-40
  );
  --amplify-components-button-primary-hover-background-color: var(
    --amplify-colors-brand-primary-50
  );

  --amplify-components-button-color: #f2f2f2;
  --amplify-components-button-hover-color: #f2f2f2;
  --amplify-components-button-background-color: transparent;
  --amplify-components-button-hover-background-color: var(
    --amplify-colors-brand-primary-10
  );
  --amplify-components-button-active-color: #f2f2f2;
  --amplify-components-button-focus-color: #f2f2f2;
  --amplify-components-button-primary-loading-background-color: #7373FF33;
  --amplify-components-button-primary-disabled-background-color: #7373FF33;

  --amplify-components-togglebutton-hover-background-color: #7373FF1f;
  --amplify-components-togglebutton-pressed-color: #f2f2f2;
  --amplify-components-togglebutton-pressed-background-color: #7373FF1f;
  --amplify-components-togglebutton-focus-border-color: #7373FF1f;
  --amplify-components-togglebutton-focus-color: #f2f2f2

  /* --amplify-components-button-border-color: #333742;
  --amplify-components-button-hover-border-color: #333742; */

  --amplify-components-field-font-size: var(--amplify-font-sizes-small);
  --amplify-components-field-label-color: #f2f2f299;

  --amplify-components-fieldcontrol-color: #f2f2f2;
  --amplify-components-fieldcontrol-border-color: #333742;
  --amplify-components-fieldcontrol-focus-border-color: var(
    --amplify-colors-brand-primary-10
  );

  --amplify-components-fieldmessages-error-color: #fd6860;
  --amplify-components-fieldcontrol-error-border-color: #fd6860;
  --amplify-components-text-error-color:#fd6860;

  --amplify-components-alert-error-color: #fd6860;
  --amplify-components-alert-error-background-color: #2B1924;
}
