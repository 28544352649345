.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 100dvh;
  flex: 1;
  width: 100%;
  padding: 40px 24px;
  background-color: transparent;
}

.headerLogoHorizontal {
  display: flex;
  min-height: 40px;
  height: 40px;
  margin-bottom: 100px;
}
.headerLogoVertical {
  display: none;
  min-height: 86px;
  height: 86px;
  margin-bottom: 24px;
}



.cicleBgContainer{
  position: absolute;
  top: 0;
  right: 0;
}
.circleBg{
  transform: translateY(20%);
  background: linear-gradient(0deg, #66DDD7 0.34%, #8A43FF 100%);
  filter: blur(8vw);
  border-radius: 50%;
  height: 25vw;
  width: 25vw;
}


@media (max-width: 1200px) {
  .headerLogoHorizontal {
    display: none;
  }
  .headerLogoVertical {
    display: block !important;
  }
  .formContainer > button {
    width: 100%;
  }
  .cicleBgContainer{
    display: none;
  }
  .cornerLines{
    display: none;
  }
}


.title {
  margin-bottom: 8px;
  text-align: center;
  color: #f2f2f2;
}

.subtitle {
  text-align: center;
  color: rgba(242, 242, 242, 0.6);
}

.urlInput :global(.amplify-input) {
  padding-inline-end: 100px;
}

.inputInnerAddonText {
  font-size: 16px;
  color: rgba(242, 242, 242, 0.6);
  margin: auto 0;
  padding: 8px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.formContainer > * {
  width: 100%;
  margin-top: 16px;
}

.formContainer > button {
  margin-top: 40px;
  margin-bottom: 32px;
  align-self: center;
}
