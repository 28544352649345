.container {
  display: flex;
  flex-direction: column;
  background-color: #060b19;
  flex:1;
  overflow: auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex:1;
}

.cicleBgContainer{
  position: absolute;
  top: 0;
  right: 0;
}
.circleBg{
  transform: translateY(20%);
  background: linear-gradient(0deg, #66DDD7 0.34%, #8A43FF 100%);
  filter: blur(8vw);
  border-radius: 50%;
  height: 50vw;
  width: 50vw;
  max-width: 400px;
  max-height: 400px;
}


.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content [data-amplify-authenticator]{
  margin: 0;
}

.sidePanelContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  color:#FFFFFF;
  position: relative;
}

.sidePanel{
  max-width: 545px;
  background: rgba(42, 57, 71, 0.5);
  backdrop-filter: blur(14px);
  padding: 40px 44px;
  border-radius: 4px;
  position: relative;
}


.sidePanel::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px; /* control the border thickness */
  background:  linear-gradient(to left, #743ad5, #d53a9d);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
}



.sidePanelSection{
  margin-top:24px;
}

.headerLogoContainer {
  display: flex;
  justify-content: center;
}

.headerLogoHorizontal {
  min-height: 37px;
  height: 37px;
  margin: 54px;
}
.headerLogoVertical {
  display: none;
  min-height: 86px;
  height: 86px;
  margin: 54px;
}

.createAccountHeader {
  font-weight: 500;
  font-size: 24px;
  text-align: center;

  color: #f2f2f2;
}

.signInFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  margin-top: -16px;
  margin-bottom: 32px;
}
.forgotPasswordSection{
  margin-bottom:40px;
  align-self: flex-end;
}

@media (max-width: 1200px) {
  .sidePanelContainer {
    border-left:0;
    text-align: center;
  }
  .content {
    margin: 32px 0;
  }
  .withPanel > .wrapper{
    flex-direction: column;
  }
  .headerLogoHorizontal {
    display: none;
  }
  .headerLogoVertical {
    display: block !important;
  }
  .cicleBgContainer{
    display: none;
  }
  .cornerLines{
    display: none;
  }
  .signInFooter {
    margin-bottom: 0;
  }
}

[data-amplify-authenticator] {

  --amplify-components-heading-color: var(--amplify-colors-neutral-20);

  --amplify-components-tabs-item-color: #f2f2f2;
  --amplify-components-tabs-item-active-color: var(
    --amplify-components-tabs-item-color
  );
  --amplify-components-tabs-item-focus-color: var(--amplify-components-tabs-item-color);
  --amplify-components-tabs-item-hover-color: var(
    --amplify-colors-brand-primary-10
  );
  --amplify-components-tabs-item-active-border-color: var(
    --amplify-components-tabs-item-color
  );
  --amplify-components-tabs-item-border-color: transparent;

  --amplify-components-text-color: var(--amplify-colors-font-interactive);
  --amplify-components-button-color: var(--amplify-colors-font-interactive);
  --amplify-components-radiogroup-radio-label-color: var(
    --amplify-colors-font-interactive
  );
  
  --amplify-components-button-link-color: var(
    --amplify-colors-brand-primary-10
  );
  --amplify-components-button-link-hover-color: var(
    --amplify-colors-brand-primary-40
  );
  --amplify-components-button-link-hover-background-color: transparent;
}

[data-amplify-authenticator] [data-amplify-router] > div {
  overflow: hidden;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: 0;
  box-shadow: unset;
}


[data-amplify-authenticator] [data-or-container] [data-or-line] {
  background: var(--amplify-colors-background-primary);
  color: #93959b;
}
