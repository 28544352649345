
.loadingContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #060b19;
}

.headerLogoContainer {
  display: flex;
  justify-content: center;
}
.headerLogo {
  min-height: 86px;
  height: 86px;
  margin: 54px;
}
