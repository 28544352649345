@font-face {
  font-family: 'Outfit';
  src: local('Outfit'), url(./assets/font/Outfit-VariableFont_wght.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html {
  min-height: 100%;
}

* {
  overscroll-behavior: contain;
}

body {
  font-family: Outfit, Helvetica, Arial, sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: unset;
}

button:focus {
  outline: none;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: transparent;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #33AAFC;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-moz-selection {
  /* Code for Firefox */
  color: #E6EBF0;
  background: #7373FF;
}

::selection {
  color: #E6EBF0;
  background: #7373FF;
}